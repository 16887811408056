<template>
    <section style="min-height: 65vh">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 40vh;
        "
      >
        <h2 style="color: var(--yellow); font-weight: bolder; text-align: center">
          Be An Affiliate
        </h2>
  
        <div
          class="d-flex flex-column mt-2 justify-content-center align-items-center"
          style="width: 100%; padding: 0 10px"
        >
          <div v-if="affiliateLink" class="affiliate-link">
            <span>{{ affiliateLink }}</span>
          </div>
          <div v-else-if="existmsg" class="existing-code">
            <span>{{ existmsg }}</span>
          </div>
  
          <input
            type="text"
            class="affiliate-input"
            v-model="code"
            readonly
            style="
              cursor: not-allowed;
              text-align: center;
              color: var(--darkBackground);
              font-size: 1.5rem;
              font-weight: bold;
            "
          />
  
          <button class="join-btn mt-2" @click="SendCode" :disabled="loading">
            <span v-if="loading">Loading...</span>
            <span v-else>Join</span>
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import affiliate from "../services/affiliate";
  
  export default {
    name: "Referral",
    data() {
      return {
        myProfile: null,
        code: "",
        loading: false,
        affiliateLink: "",
        existmsg: "",
      };
    },
    mounted() {
      this.myProfile = this.getProfile();
      if (this.myProfile) {
        this.code = this.myProfile.referral_code;
      }
      if (!this.myProfile) {
            this.setError("Login", "Please login to proceed");
            this.$router.push({ name: "login", params: {} });
            return;
        }
    },
    methods: {
      async SendCode() {
        var vm = this;
        this.loading = true;
  
        try {
          const baseUrl = process.env.VUE_APP_AFFILIATE_BASE_URL;
      const path = `${baseUrl}/join`;  
          // Call affiliate service to post data
          const response = await affiliate.post(
            path,
            {
              code: this.code,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "api-key": vm.getAuth(),
              },
            }
          );
  
          console.log("Response from server:", response);
  
          if (response.data && response.data.data) {
            vm.affiliateLink = response.data.data;
            vm.existmsg = "";
          } else {
            vm.affiliateLink = "";
          }
          setTimeout(() => {
            this.$router.push("/referrals");
          }, 1000);
        } catch (err) {
          console.error("Error sending code:", err);
          if (err.response && err.response.status === 409) {
            // Handle 409 Conflict error
            vm.existmsg = `${vm.code} already exists`;
            vm.affiliateLink = "";
          }
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .affiliate-input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    color:var(--darkBackground);
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #f0f0f0;
  }
  
  .join-btn {
    padding: 10px 20px;
    font-weight: bold;
    color: var(--darkBackground);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--yellow);
    width: 100%;
    max-width: 400px;
    height: 50px;
  }
  
  .affiliate-link {
    background-color: #28a745;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .existing-code {
    background-color: #dc3545;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .affiliate-link span,
  .existing-code span {
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
  }
  </style>